@import "./global.scss";


.cntwrapper{
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 50%,
        $beigeBack 50%,
        $beigeBack 100%
      ),
      url("../../public/assets/images/backgrounds/paper_texture.png");

      height: 85vh;

      padding: 0 10% 100px 10%;

      @include devices('tablet'){
        padding: 0 0 100px 0;
      }
}

