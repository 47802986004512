.openbtn {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 20px;
  top: 50px;
  right: 50px;
  z-index: 21;
  cursor: pointer;
}

.openbtn svg {
  flex: 0 1 auto;
}

.hide {
  opacity: 0 !important;
}

@media (max-width: 810px) {
  .openbtn {
    top: 25px;
    right: 10%;
  }
}
.openbtn img {
  padding: 2px 0px;
  margin: 0;
}

.menu {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url(../../../../public/assets/images/backgrounds/paper_texture.png);
  background-color: white;
  z-index: 20;
}

.linkwrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  flex-direction: column;
  padding: 10% 0px;
}

.linkwrapper h2 a {
  color: #c29134;
  font-size: 60px;
  font-family: "Uniform 4";
}

.iconwrapper {
  padding: 5% 0px;
  width: 40%;
  height: 100%;
}
.iconwrapper .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-right: 2px solid #697753;
}

.iconwrapper .icons h2 {
  color: #c29134;
}

.menu {
  overflow: hidden;
}

.mobile {
  display: none;
}

@media (max-width: 811px) {
  .mobile {
    display: flex;
  }
  .menu {
    flex-direction: column-reverse;
  }

  .iconwrapper {
    display: none;
  }

  .linkwrapper {
    height: 100%;
    width: 100%;
  }

  .linkwrapper h2 a {
    font-size: 40px;
  }
}
