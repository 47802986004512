*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Uniform 6';
}

// Colours ---------------------------------------------------
$olive : #697753; 
$beigeBack : rgb(#A2A793, 30%);
$mustard: #C29134;
$icons: #4E4B2C;
$black: #2B302C;
$darkerBeige: #E6E3D6;
// Mixins ---------------------------------------------------
@mixin flexCenter($direction){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: #{$direction};
}

@font-face {
    font-family: 'Uniform 6';
    src: url('../../public/assets/fonts/Uniform-Condensed6.woff2') format('woff2'),
        url('../../public/assets/fonts/Uniform-Condensed6.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uniform 4';
    src: url('../../public/assets/fonts/Uniform-Condensed4.woff') format('woff2'),
        url('../../public/assets/fonts/Uniform-Condensed4.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uniform 2';
    src: url('../../public/assets/fonts/Uniform-Condensed2.woff2') format('woff2'),
        url('../../public/assets/fonts/Uniform-Condensed2.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Global html ---------------------------------------------------

img{
    max-width: 100%;
}


.cgi-wrapper{
    position: relative;
    padding: 0;
    margin: 0;
    img{
        display: block;
    }
}
.cgi{
    padding: 0;
    margin: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 10px;
    color: white;
}

svg{
    max-width: 100%;
}

h2{
    font-family: 'Uniform 4';
    max-width: 100%;
}

p{
    font-family: 'Uniform 6';
    max-width: 100%;
    color: $black;
}

a{
    text-decoration: none;
}
button{
    all: unset;
}

// Medias --------------------------------------------------------


@mixin devices ($breakpoint) {
  
    @if $breakpoint == tablet {    
    @media only screen and (max-width: 811px) {
      @content;
    }
  }
 
  @if $breakpoint == mobile {
    @media only screen and (max-width: 425px) {
      @content;
    }
  }
}


.desktop{
    display: flex;

    @include devices('tablet'){
        display: none;
    }
}

.mobile{
    display: none;

    @include devices('tablet'){
        display: flex;
    }
}