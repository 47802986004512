@import "./global.scss";

.overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  .landing-wrapper {
    height: 100vh;
    background-image: url("../../public/assets/images/backgrounds/paper_texture.png");
    background-size: cover;
    background-color: white;
    width: 100vw;
  }

  .body {
    position: relative;
    @include flexCenter(row);
    height: 80%;
    width: 100%;
    img {
      padding: 0 20px;
    }
  }
}

.one-wrapper {
  height: 85vh;
  width: 100%;

  @include devices("tablet") {
    height: auto;
  }
  .top-half {
    position: relative;
    height: 80%;
    @include flexCenter(column);
    padding: 0px 10%;
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 50%,
        $darkerBeige 50%,
        $darkerBeige 100%
      ),
      url("../../public/assets/images/backgrounds/paper_texture.png");

    @include devices("tablet") {
      padding: 25px 0 0 0;
      align-items: center;
      height: auto;
      flex-direction: column;
    }
    img {
      max-height: 100%;
    }
  }

  .bottom-half {
    height: 20%;
    background-color: $darkerBeige;
    @include flexCenter(row);
    @include devices("tablet") {
      height: auto;
      padding: 0 0 25px 0;
    }
    h2 {
      font-size: 50px;
      color: $mustard;
      text-align: center;

      @include devices("tablet") {
        font-size: 24px;
      }
    }
  }
}

.two-wrapper {
  @include flexCenter(row);
}
.intro-logo {
  position: absolute;
  z-index: 19;

  @include devices("tablet") {
    position: absolute;
    top: calc((50% + 20vh) / 2);
    height: 200px;
  }
}

.exterior {
  height: 100%;

  @include devices("tablet") {
    height: auto;
  }
}

.logoholder {
  display: none;
}

@include devices("tablet") {
  .logoholder {
    display: block;
    height: 250px;
  }
}

