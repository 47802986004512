@import "./global.scss";

.avail-top {
  @include flexCenter("row");
  padding: 0 10%;
  width: 100%;

  @include devices("tablet") {
    padding: 0;
  }

  background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 50%,
      $darkerBeige 50%,
      $darkerBeige 100%
    ),
    url("../../public/assets/images/backgrounds/paper_texture.png");

  img {
    width: 100%;
  }
}

.text-break {
  width: 100%;
  @include flexCenter("row");
  padding: 5% 0px;
  background-color: $darkerBeige;
  &.shorter {
    padding: 3% 0 0;
    text-transform: uppercase;
    @include devices("tablet") {
      padding: 5% 0 0;
    }
  }
  h2 {
    color: $mustard;
    font-size: 40px;
    text-align: center;
    @include devices("tablet") {
      font-size: 24px;
    }
  }
  h3 {
    margin-bottom: 5%;
  }
}

.floor-plan {
  background-color: $darkerBeige;
  padding: 0 10%;
  width: 100%;
  img {
    padding: 0 0 1% 0;
    width: 100%;
  }
  small {
    margin-bottom: 5%;
    display: inline-block;
  }
}
.info-wrapper {
  background: linear-gradient(
    to right,
    $darkerBeige 0%,
    $darkerBeige 50%,
    white 50%,
    white 50%
  );

  padding: 0 10%;
  width: 100%;
  display: flex;

  @include devices("tablet") {
    flex-direction: column;
    padding: 0;

    background: linear-gradient(
      to right,
      white 0%,
      white 100%
    );
  }

  .info {
    padding: 100px 0px;
    background-color: white;
    width: 33%;
    @include flexCenter("row");

    @include devices("tablet") {
      width: 100%;
      padding: 0;
    }
    .content {
      @include flexCenter("column");
      justify-content: space-evenly;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding: 0px 50px;

      @include devices("tablet") {
        padding: 0;
      }
      .content-one {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        @include devices("tablet") {
          align-items: center;
          justify-content: center;
          img {
            display: none;
          }
        }
        p {
          min-width: 50%;
          font-size: 25px;
          padding: 5px 0;
          @include devices("tablet") {
            font-size: 16px;
            text-align: center;
          }
        }
        img {
          padding: 0;
          padding-right: 10px;
          margin: 0;
          height: 20px;
          width: 10%;
        }
      }
    }
  }
  .green {
    background-color: $darkerBeige;

    @include devices("tablet") {
      background-color: white;
    }
    p {
      max-width: 50%;
      color: $olive;
      font-size: 35px;
      @include devices("tablet") {
        font-size: 16px;
        text-align: center;
        padding: 50px 0 10px 0;
      }
    }
  }
}

.line{
  display: none;
  @include devices('tablet'){
    display: block;
  }
}

.icons {
  width: 100%;
  padding-bottom: 100px;
}
@media only screen and (max-width: 811px) {
  .icons {
    display: none;
  }
}

.icons_mb {
  display: none;
}
@media only screen and (max-width: 811px) {
  .icons_mb {
    padding: 50px 0px;
    width: 100%;
    display: block;
  }
}

.icon_hdr {
  text-align: center;
  font-size: 40px;
  color: #C29134;
  padding: 100px 0px 100px 0px;
}
  @media only screen and (max-width: 811px) {
    .icon_hdr {
      font-size: 24px;
      padding: 0px;
      padding-top: 50px;
    
    }
}