@import "./global.scss";

.map-wrapper {
  background-image: url(../../public/assets/images/backgrounds/paper_texture.png);
  background-size: cover;

  .map-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    h2 {
      color: $mustard;
      font-size: 50px;
      @include devices('tablet'){
        font-size: 24px;
        max-width: 50%;
        text-align: center;
      }
    }
  }
}

.timeline-wrapper {
  width: 100%;
  background-color: white;
  .timeline-section {
    display: flex;

    

    @include devices('tablet'){
    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 10px;
      }
      .south-btns:nth-child(1) {
        background-color: $mustard;
      }
      .south-btns:nth-child(2) {
        background-color: $olive;
      }
      .south-btns {
        p {
          font-size: 12px;
          color: #e6e3d6;
          cursor: pointer;
        }
      }
    }}

    @include devices("tablet") {
      flex-direction: column;
    }

    .logo {
      @include flexCenter("row");
      width: 100%;
      
      background: linear-gradient(
        to bottom,
        white 0%,
        white 50%,
        $darkerBeige 50%,
        $darkerBeige 100%
      );

      @include devices('tablet'){
        background: linear-gradient(to bottom, white 0%, white 100%);
        padding: 50px 0;
      }

      
    }
    @include devices("tablet") {
      .pins.inactive {
        display: none;
      }
    }

    .pins {
      display: flex;
      padding: 50px 50px 100px 50px;
      justify-content: space-evenly;
      width: 50%;

      img{
        padding-right: 50px;
      }

      @include devices("tablet") {
        width: 100%;
        img{
          padding-right: 0px;
        }
      }

      .pin {
        padding: 10px 0;
        font-family: 'Uniform 2';
        @include devices('tablet'){
          font-size: 16px;
        }
      }

      p {
        color: #4e4b2c;
        font-size: 22px;
      }

      @include devices('tablet'){
        text-align: center;
      }
    }
    width: 100%;
    .l {
      border-right: $olive solid 2px;
      
      @include devices("tablet") {
        background-color: rgb(194, 145, 52, 0.23);
        p {
          color: $mustard;
        }
      }
    }
    .r {
      @include devices("tablet") {
        background-color: rgb(78, 75, 44, 0.23);
        p {
          color: $olive;
        }
      }
    }

    // Eat Drink Play
    .edp {
      background-color: $darkerBeige;
      .text {
        padding: 200px 25% 10px 25%;
        text-align: center;
        font-size: 25px;
        color: #4e4b2c;
      }
      h2 {
        color: $mustard;
        text-align: center;
        font-size: 50px;
      }
      .img {
        padding: 100px 50px;
      }
      .tag {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .tag-wrapper {
          background-color: $olive;
          padding: 10px;

          p {
            font-size: 20px;
            color: #e6e3d6;
          }
        }
      }
    }

    .edp.r{
      width: 50%;
    }

    .edp.l {
      width: 50%;
      
      .img-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 50px;
      }
      .tag {
        justify-content: flex-end;
      }
    }
  }
}

.illust {
  .ill-title {

    padding-top: 100px;
    @include devices('tablet'){
      padding: 0;
    }
    h2 {
      text-align: center;
      color: $olive;
      font-size: 50px;
      @include devices('tablet'){
        color: $mustard;
        font-size: 24px;

      }
    }
  }
  img {
    width: 100%;
  }
}
.timeline-section:nth-child(4){
  display: none;
}

@include devices('tablet'){
  .timeline-section:nth-child(3){
    display: none;
  }

  .timeline-section:nth-child(4){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;  
    h2{
      font-size: 24px;
      color: $mustard;
    }
    P{
      text-align: center;
      padding: 50px 20% 50px 20%;
      font-size: 16px;
    }
  }

  .timeline-section{
    .r.logo{
      display: none;
    }
  }


}
.l.edp{
  position: relative;
}

.timelineadd{

  position: absolute;
  right: -14.5px;
  top: 0;

  @include devices('tablet'){
    display: none;
  }
}

.timelineadd.arr{
  right: -57px;
  top: 25px
}

.paper-wrapper{
  .building-img{
  .hdr-wrapper:first-child{
      background-image: url(../../public/assets/images/backgrounds/paper_texture.png);

  }
}
}
.react-transform-component.transform-component-module_content__FBWxo{
  width: 100%;
} 