.container {
  width: 100%;
  height: fit-content;
  margin: 100px 0px 50px 0px;
  @media (max-width: 811px) {
    margin: 0px 0px 25px 0px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

#swipers {
  .swiper-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 50px;
    @media (max-width: 811px) {
      padding-top: 10px;
    }
  }

  .swiper-pagination-bullet {
    padding: 0px 10px;
    content: url(../../../public/assets/images/pag.svg);

    @media (max-width: 811px) {
      content: url(../../../public/assets/images/pag-mb.svg);
    }
  }
  .swiper-pagination-bullet-active {
    content: url(../../../public/assets/images/active-pag.svg);

    @media (max-width: 811px) {
      content: url(../../../public/assets/images/active-pag-mb.svg);
    }
  }
  .swiper-button-prev {
    color: red;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    position: relative;
  }

  .slide-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .caption {
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    color: #697753;
    font-size: 16px;
    font-family: "Uniform 4";

    @media (max-width: 811px) {
      position: relative;
      display: block;
    }
  }
  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    /* border-top: 2px solid #697753; */
  }

  .time h2 {
    background-color: #697753;
    padding: 10px 5px;
    color: white;
    font-size: 16px;
    font-family: "Uniform 4";
    width: fit-content;
  }
}

.arrow-l {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 12%;
}

.arrow-r {
  position: absolute;
  top: 40%;
  z-index: 10;
  right: 12%;
  transform: rotate(180deg);
}
