@import "./global.scss";


.header-wrapper{
    height: 15vh;
    background-image: url('../../public/assets/images/backgrounds/paper_texture.png');
    @include flexCenter(row);
    justify-content: flex-start;
    padding: 0px 10%;
    width: 100%;
    @include devices('tablet'){
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        padding: 50px 0px 20px 0px;
    }

    .header{
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        // position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        color: $mustard;
        @include devices('tablet'){
            flex-direction: column;
            padding: 25px 0 0 0;
        }
    }
}