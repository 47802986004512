@import "./global.scss";

$commonPad: 25px;

.footer-wrapper {
  width: 100%;
  padding: 50px;
  background-color: $beigeBack;
  flex-wrap: wrap-reverse;
  display: flex;

  p {
    color: $icons;
  }

  h2 {
    font-size: 48px;
    color: $mustard;
    text-transform: uppercase;
  }
  @include devices(tablet) {
    padding: 0px 0px 50px 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .l-container {
    min-height: 500px;
    min-width: 200px;
    width: 25%;
    border-right: 2px solid $olive;

    @include flexCenter(column);
    padding-right: 20px;
    justify-content: space-between;

    @include devices(tablet) {
      border-right: none;
      border-top: 2px solid $olive;
      padding: 0;
      margin-top: 50px;
      width: 75%;
    }
    .f-container {
      @include flexCenter(column);
      width: 80%;
    }
  }

  .r-container {
    width: 75%;
    padding: 50px 50px 0px 50px;
    @include devices(tablet) {
      @include flexCenter(col);
      width: 100%;
    }
    .r-wrapper {
      flex-wrap: wrap;
      img {
        height: 80px;
      }

      @include devices(tablet) {
        justify-content: center;
        display: flex;

        h2 {
          font-size: 23px;
        }
      }

      .wrapper {
        @include flexCenter(row);
        justify-content: space-between;
        align-items: flex-start;
        max-width: 100%;
        flex-wrap: wrap;

        @include devices(tablet) {
          align-items: center;
          justify-content: center;
          padding-top: 25px;
        }

        .agents {
          flex-direction: column;
          padding-bottom: $commonPad;
          h2 {
            font-size: 20px;
          }
          p {
            font-size: 20px;
          }
        }
        .download {
          padding-top: 80px;

          @include devices(tablet) {
            padding-top: 20px;
            padding-bottom: 20px;
          }

          h2 {
            color: $icons;
            font-size: 20px;
            margin-bottom: 30px;
          }
          .address h2 {
            color: $mustard;
          }

          button {
            background-color: $mustard;
            padding: 10px 40px;
            margin-bottom: $commonPad;
            h2 {
              margin: 0;
              padding: 0;
              color: white;
              font-size: 18px;
            }
          }
        }
      }

      .misrep {
        padding-top: 25px;
        p {
          font-size: 12px;
        }
        h2 {
          font-size: 27px;
        }
      }
    }
  }
}

@include devices("tablet") {
  .hdr {
    border-top: 2px solid $olive;
    padding-top: 25px;
  }
}
