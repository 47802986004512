@import "./global.scss";

.building-wrapper-top {
  scroll-snap-type: y mandatory;
  @include devices("tablet") {
    display: none;
  }
  background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 22%,
      #f2f1ec 22%,
      #f2f1ec 100%
    ),
    url("../../public/assets/images/backgrounds/paper_texture.png");

  .l {
    width: 50%;
    border-right: #697753 solid 2px;
    display: flex;
    position: relative;

    img {
      width: 90%;
    }

    .timelineadd {
      width: auto;
      position: absolute;
      top: 100%;
    }
    .arr.timelineadd {
      right: -47px;
      top: calc(100% - 50px);
    }
  }
  .r {
    @include flexCenter("row");
    width: 50%;
    font-size: 50px;
    color: $mustard;
    text-align: center;
    border-right: #697753 solid 2px;
    position: relative;

    .timestamp {
      position: absolute;
      top: 60%;
      left: 0;
      background-color: $olive;
      padding: 15px 15px;
      p {
        color: #e6e3d6;
        font-size: 45px;
        font-family: "Uniform 4";
      }
    }

    h2 {
      font-size: 50px;
      @include flexCenter("row");
      text-wrap: balance;
      width: 100%;
    }
  }
  .container {
    display: flex;
    margin: 0;
  }

  .today {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.vr {
  border-left: 2px #697753 solid;
  height: 200px;

  @include devices("tablet") {
    border-left: none;
    border-bottom: 2px #697753 solid;
    height: auto;
  }
}

.building-img {
  @include flexCenter(column);
  background-size: cover;
  .img {
    width: 100%;
  }

  .hdr-wrapper {
    padding: 100px 0px;
    @include flexCenter(column);
    width: 100%;
    @include devices("tablet") {
      padding: 50px 0px;
    }
    h2 {
      font-family: "Uniform 4";
      max-width: 50%;
      text-align: center;
      font-size: 50px;
      color: #697753;
      text-wrap: balance;

      @include devices("tablet") {
        font-size: 24px;
        max-width: none;
        padding: 0px 10%;
      }
    }

    .container {
      width: 90%;
      display: flex;
      justify-content: space-around;

      @include devices("tablet") {
        flex-direction: column;
      }

      .section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        max-width: 500px;

        p {
          font-size: 25px;
          color: $icons;
          text-wrap: balance;
          text-align: center;
        }
        @include devices("tablet") {
          padding: 20px 0px;
          img {
            padding: 15px 0px;
          }
          p {
            font-size: 16px;
            padding: 15px 0px;
          }
        }
      }
      .section:first-child {
        border: none;
      }
    }
  }
}

.building-wrapper-btm {
  .split-container {
    background: linear-gradient(
      to right,
      rgb($olive, 30%) 0%,
      rgb($olive, 30%) 50%,
      #f2f1ec 50%,
      #f2f1ec 100%
    );
    display: flex;
    width: 100%;
    @include devices("tablet") {
      background: linear-gradient(
        to right,
        rgb($olive, 30%) 0%,
        rgb($olive, 30%) 100%
      );
    }

    .l {
      display: flex;
      width: 50%;
      padding: 5%;
      justify-content: space-evenly;
      align-items: flex-start;
      img {
        width: 25%;

        @include devices("tablet") {
          padding: 10% 0 0 0;
        }
      }
      p {
        width: 50%;
        font-size: 35px;
        max-width: 25%;
        text-wrap: balance;
        color: $olive;

        @include devices("tablet") {
          max-width: 50%;
        }
      }
    }
    .r {
      width: 50%;
      padding: 5%;
      font-size: 25px;
      color: $icons;
    }

    @include devices("tablet") {
      flex-direction: column;

      .l {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          width: 100%;
          text-align: center;
          font-size: 16px;
          padding: 20px 0px;

          @include devices("tablet") {
            padding: 10px;
          }
        }
      }
      .r {
        width: 100%;
        padding: 0px 15% 15% 15%;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.building-wrapper-top-mobile {
  display: none;
  @include devices("tablet") {
    display: flex;
    flex-direction: column;
    position: relative;
    .firstimage {
      width: 100%;
      height: 100%;
      img {
        position: relative;
      }
    }

    .overlay {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      height: 100%;
      position: absolute;
      text-align: center;
      color: white;
      font-size: 24px;
    }

    h2 {
      text-align: center;
      color: $mustard;
      padding: 5px 0px;
    }

    .border-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      .ninetwenty {
        position: absolute;
        top: -10px;
      }
      .border-wrapper-l {
        width: 0%;
        border-right: 2px solid $olive;
        height: 100px;
      }
    }
  }
}
@include devices("tablet") {
  .timestamp {
    position: absolute;
    top: 0;
    left: 50;
    background-color: $olive;
    padding: 5px 10px;
    p {
      color: #e6e3d6;
      font-size: 18px;
    }
  }
  .today {
    padding: 5px 10px;
    background-color: $olive;

    p {
      color: #e6e3d6;
      font-size: 18px;
      font-family: "Uniform 4";
    }
  }
}

div.building-img:nth-child(1) > div:nth-child(1) {
  @include devices("tablet") {
    padding: 50px 0px 0px 0px;
  }
}
.captionstock {
  display: none;
  @include devices("tablet") {
    background-color: white;
    padding: 10px;
    color: #697753;
    font-size: 12px;
    font-family: "Uniform 4";
  }
}

.paper-wrapper > div:nth-child(1) > div:nth-child(1) > h2:nth-child(1) {
  padding: 100px 0px;
  @include devices("tablet") {
    padding: 0;
  }
}
.icons {
  width: 100%;
  padding-bottom: 100px;
}
@media only screen and (max-width: 811px) {
  .icons {
    display: none;
  }
}

.icons_mb {
  display: none;
}
@media only screen and (max-width: 811px) {
  .icons_mb {
    padding: 50px 0px;
    width: 100%;
    display: block;
  }
}

.icon_hdr {
  text-align: center;
  font-size: 40px;
  color: #C29134;
  padding: 100px 0px 100px 0px;
}
  @media only screen and (max-width: 811px) {
    .icon_hdr {
      font-size: 24px;
      padding: 0px;
      padding-top: 50px;
    
    }
}